import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class NotFound extends Component {

  render() {
      return (
          <div className="NotFound">
              <p>Page does not exists. Would you like to go <Link to="/">Home</Link></p>
          </div>
      );
  }

}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
