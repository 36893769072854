// view-source: https://cozmo.github.io/jsQR/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addScan } from 'store/actions/barcode';
import cx from 'classnames';
// import beep from 'assets/audio/beep';
import jsQR from "jsqr";
import * as moment from 'moment-timezone';

class Barcode extends Component {

  canvasRef = null;
  videoRef = null;

  constructor(props){
    super(props)
    this.state = {
      result: null,
      loaded: false,
      outputMessage: 'Unable to access video stream (please make sure you have a webcam enabled)',
    }
  }

  componentDidMount() {

    this.videoRef = document.createElement("video");
    const self = this;

    // Use facingMode: environment to attemt to get the rear camera on phones
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      
      navigator.mediaDevices.getUserMedia({ video: { facingMode: "user" } })
      .then(stream => {
        
        self.videoRef.srcObject = stream;
        self.videoRef.setAttribute("playsinline", true); // required to tell iOS safari we don't want fullscreen
        self.videoRef.play();

        self.setState({ outputMessage: "Loading video..." });
        window.requestAnimationFrame(self.tick);
      })
      .catch(e => console.warn(e.message));
    
    }
  }

  tick = async () => {

    const { addScan, barcode } = this.props;

    if (!this.canvasRef)
      return;

    const canvas = this.canvasRef.getContext("2d");

    if (this.videoRef.readyState === this.videoRef.HAVE_ENOUGH_DATA) {
      
      const newState = {};
      if (!this.state.loaded)
        newState.loaded = true;
      if (this.state.outputMessage.length>0)
        newState.outputMessage = '';
      if (Object.keys(newState).length>0)
        this.setState(newState);

      this.canvasRef.height = this.videoRef.videoHeight;
      this.canvasRef.width = this.videoRef.videoWidth;

      canvas.drawImage(this.videoRef, 0, 0, this.canvasRef.width, this.canvasRef.height);
      const imageData = canvas.getImageData(0, 0, this.canvasRef.width, this.canvasRef.height);

      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      });
      
      if (code!==null && code.data.length>0 && !this.props.loading) {

        // console.log({code});

        this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
        this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
        this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
        this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");

        // this.setState({ result: code.data });
        // beep.play();

        // add scan to scan history
        addScan(code.data);
        
        // scanned in the last 3 seconds
        const alreadyScanned = barcode.reduce((acc, scan) => {
          const { code: scanCode, time } = scan;
          return acc || ( code.data === scanCode && moment().subtract(3, 'second').isBefore(moment(time)));
        }, false);

        if (!alreadyScanned)
          await this.props.login(code.data);

      } else {

        // this.setState({ result: null });
      }
      
    }

    window.requestAnimationFrame(this.tick);
  }

  drawLine = (begin, end, color) => {

    // const canvas = this.canvasRef.getContext("2d");

    // canvas.beginPath();
    // canvas.moveTo(begin.x, begin.y);
    // canvas.lineTo(end.x, end.y);
    // canvas.lineWidth = 4;
    // canvas.strokeStyle = color;
    // canvas.stroke();
  }

  render() {

    const { loaded, outputMessage, result } = this.state;

    return (
      <div 
        className={cx({
          Barcode: true,
          loaded: loaded,
        })}
      >
        <canvas 
          className="canvas"
          ref={ref => {
            // console.log(ref);
            this.canvasRef = ref;
          }}
        ></canvas>
        <div className="outputMessage">{outputMessage}</div>
        <div className="outputData">{result}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  
  const { 
    auth: { isFetching },
    barcode,
  } = state;

  return {
    barcode,
    loading: isFetching, // dont scan when we are doing other stuff
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addScan: code => dispatch(addScan(code)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Barcode);