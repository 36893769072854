import React, { Component } from 'react';
import { Field, Form } from 'redux-form';
import { Button } from 'grommet';
import { Clear, Login } from 'grommet-icons';
import { renderFormField } from 'helpers/FormFields';

class UsernamePassword extends Component {

  render() {

    const { dirty, handleSubmit, initialize } = this.props;

    return (
      <Form 
        className="UsernamePassword"
        onSubmit={handleSubmit}
      >
        <Field
          label="Username"
          name="username"
          component={renderFormField}
        />
        <Field
          label="Password"
          name="password"
          type="password"
          component={renderFormField}
        />
        <Button
          icon={<Clear />}
          label="Clear"
          color="dark-2"
          margin="small"
          disabled={!dirty}
          onClick={() => initialize({})}
        />
        <Button
          icon={<Login />}
          margin="small"
          label="Login"
          type="submit"
          primary={true}
          color="neutral-1"
        />
      </Form>
    );
  }
}

export default UsernamePassword;