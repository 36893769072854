import { connect } from 'react-redux';
import store from 'store';
import { authorize, checkin } from 'store/actions/auth';
import { addMessage } from 'store/actions/messages';
import { initialize, reduxForm } from 'redux-form';
import UsernamePasswordBase from '../../Auth/UsernamePasswordBase';
import beep from 'assets/audio/beep';

const formName = 'authorizelogin';

const validate = vals => {
  const errors = {};

  ['username', 'password'].forEach(fieldName => {
    if (!(fieldName in vals) || fieldName.length<1) {
      errors[fieldName] = 'Required';
    }
  });
 
  return errors;
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: data => {
      
      const { username, password } = data;
      
      return authorize(username, password)
      .then(json => {
        
        beep.play();

        const { access_civicrm, contact_id } = json.values;

        if (!access_civicrm)
          throw new Error('User not authorized');
        
        const state = store.getState();
      
        const { 
          auth: { contactId },
        } = state;

        dispatch(checkin(contactId, contact_id));

        return json;
      })
      .catch(error => {

        dispatch(addMessage(error.message, 'error'));

        const newData = { username };
        dispatch(initialize([formName], newData));

        return false;
      });
      
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues: {},
    validate,
  })(UsernamePasswordBase)
);