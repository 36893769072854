import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { Box } from 'grommet';

import HelpDialog from './HelpDialog';
import PrivacyPolicyDialog from './PrivacyPolicyDialog';
import TOSDialog from './TOSDialog';
import AboutDialog from './AboutDialog';

class Footer extends Component {

  constructor(props){
    super(props);
    this.state = {
      helpOpen: false,
      privacyPolicyOpen: false,
      TOSOpen: false,
      aboutOpen: false,
    };
  }

  toggleHelpOpen = (state=null) => this.setState({helpOpen: state===null ? !this.state.helpOpen : state})
  togglePrivacyPolicyOpen = (state=null) => this.setState({privacyPolicyOpen: state===null ? !this.state.privacyPolicyOpen : state})
  toggleTOSOpen = (state=null) => this.setState({TOSOpen: state===null ? !this.state.TOSOpen : state})
  toggleAboutOpen = (state=null) => this.setState({aboutOpen: state===null ? !this.state.aboutOpen : state})

  render() {

    const { helpOpen, privacyPolicyOpen, TOSOpen, aboutOpen } = this.state;

    return (
      <div className="Footer">
        <Box
          tag='footer'
          direction='row'
          align='center'
          justify='center'
          background="dark-1"
          pad={{ left: 'medium', right: 'small', vertical: 'small' }}
          elevation='medium'
          {...this.props}
        >
          <span className="fake-link" onClick={() => this.toggleHelpOpen(true)}>FAQ</span>
          <span className="spacer">|</span>
          <span className="fake-link" onClick={() => this.togglePrivacyPolicyOpen(true)}>Privacy Policy</span>
          <span className="spacer">|</span>
          <span className="fake-link" onClick={() => this.toggleTOSOpen(true)}>Terms of Service</span>
          <span className="spacer">|</span>
          <span className="fake-link" onClick={() => this.toggleAboutOpen(true)}>About</span>
          <HelpDialog open={helpOpen} toggle={this.toggleHelpOpen} />
          <PrivacyPolicyDialog open={privacyPolicyOpen} toggle={this.togglePrivacyPolicyOpen} />
          <TOSDialog open={TOSOpen} toggle={this.toggleTOSOpen} />
          <AboutDialog open={aboutOpen} toggle={this.toggleAboutOpen} />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedIn, testMode } = state.auth;
  return {
    loggedIn,
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Footer);