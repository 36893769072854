// view-source: https://cozmo.github.io/jsQR/
import { connect } from 'react-redux';
import { barcodeLogin } from 'store/actions/auth';
import BarcodeBase from './BarcodeBase';

const mapStateToProps = (state) => {
  const { isFetching } = state.auth;
  return {
    loading: isFetching, // dont scan when we are doing other stuff
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: code => dispatch(barcodeLogin(code)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(BarcodeBase);