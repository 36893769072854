import React, { Component } from 'react';
import { Box } from 'grommet';
import { MapLocation } from 'grommet-icons';

import MapDisplay from './Map/MapDisplay';

class Map extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      mapDisplayOpen: false,
    }
  }

  toggleMapDisplayOpen = (state=null) => this.setState({ mapDisplayOpen: state===null ? !this.state.mapDisplayOpen : state });

  render() {

    const { mapDisplayOpen } = this.state;

    return (
      <div className="Map">
        <Box
          className="floatingmapcontainer"
          onClick={() => this.toggleMapDisplayOpen()}
          background="dark-5"
          border={{
            side: "all",
            // color: "light-4",
            size: "medium"
          }}
          elevation="medium"
          pad="small"
          round="large"
        >
          <MapLocation
            className="mapicon"
            style={{
              width: 60,
              height: 60,
            }}
            color="light-1"
          />
        </Box>
        <MapDisplay open={mapDisplayOpen} toggle={this.toggleMapDisplayOpen} />
      </div>
    );
  }
}

export default Map;