import initialState, { defaultState } from "../initialState";
import constants from "../constants";
import { cloneDeep } from 'lodash-es';

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const listReducer = (state = initialState.list, action) => {
  // console.log("Running action:", action);
  const newState = {...state};
  
  switch(action.type)
  {
    case constants.LIST_CURRENT_PAGE:
      newState[action.value.list].currentPage = action.value.currentPage;
      return newState;
    case constants.LIST_PAGE_SIZE:
      newState[action.value.list].pageSize = action.value.pageSize;
      newState[action.value.list].currentPage = action.value.currentPage;
      return newState;
    case constants.LIST_SORTING:
      newState[action.value.list].sortBy = action.value.sortBy;
      return newState;
    case constants.LIST_DATES:
      const { startDate, endDate, date } = action.value;
      newState[action.value.list].startDate = startDate;
      newState[action.value.list].endDate = endDate;
      newState[action.value.list].date = date;
      newState[action.value.list].currentPage = 0;
      return newState;
    case constants.LIST_SEARCH_VALUE:
      newState[action.value.list].searchValue = action.value.searchValue;
      newState[action.value.list].currentPage = 0;
      return newState;
    case constants.LIST_LOADING_TABLE_STATE:
      newState[action.value.list].loadingTableState = action.value.loadingTableState;
      return newState;
    case constants.LIST_TABLE_STATE:
      newState[action.value.list].tableState = action.value.tableState;
      if (action.value.tableState.length===0) {
        newState[action.value.list].data = null;
        newState[action.value.list].count = null;
      }
      return newState;
    case constants.LIST_DATA:
      newState[action.value.list].data = action.value.response.data===null ? null : cloneDeep(action.value.response.data);
      newState[action.value.list].count = action.value.response.total;
      return newState;
    case constants.LIST_DATA_UPDATE:
      if (newState[action.value.list].data===null) return newState;
      const { updates, compartor, merge } = action.value;
      const updatedData = [...newState[action.value.list].data];
      updates.forEach(update => {
        const i = updatedData.findIndex((current) => compartor(current, update));
        // console.log(i);
        if (i===-1) return;
        if (merge) {
          updatedData[i] = Object.assign({},updatedData[i],update);
        } else {
          updatedData[i] = update;
        }
      });
      newState[action.value.list].data = updatedData;
      return newState;
    case constants.LOGOUT:
      return cloneDeep(defaultState.list);
    default:
      return newState;
  }
}

export default listReducer;