import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from 'store/actions/auth';
import { clearMessages } from 'store/actions/messages';
import { Box, Button, Heading, Layer } from 'grommet';
// import { Close } from 'grommet-icons';

class Messages extends Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      messageInfo: null,
      closeTimeout: null,
    };
    
    if (props.messages.length>0) {
      this.state.open = true;
      this.state.messageInfo = props.messages[0];
    }
    
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    
    if (
      // messages before, no message now
      (prevProps.messages.length>0 && this.props.messages.length===0) 
    ) {
      this.setState({
        open: false,
        messageInfo: null,
      });
    }

    // adding messages
    if (
      // no message before, messages now
      (prevProps.messages.length===0 && this.props.messages.length>0) ||
      // messages before and now, but they are different
      (prevProps.messages.length>0 && this.props.messages.length>0 && prevProps.messages[0].id!==this.props.messages[0].id)
    ) {

      // clear any previous timeout
      if (this.state.closeTimeout!==null) {
        clearTimeout(this.state.closeTimeout);
      }

      // automaticall clear message
      const { id } = this.props.messages;
      const closeTimeout = setTimeout(() => this.props.handleClearMessage(id), 7000);

      this.setState({
        open: true,
        messageInfo: this.props.messages[0],
        closeTimeout,
      });
    }

    // removing messages
    if (
      // no message before, messages now
      (prevProps.messages.length>0 && this.props.messages.length===0)
    ) {
      if (this.state.closeTimeout!==null) {
        clearTimeout(this.state.closeTimeout);
      }
    }

    // // lots of messages, start closing the chain
    // if (this.props.messages.length>1) {
    //   this.handleExited(this.props.messages[0].id);
    // }

    // console.log('prevProps', prevProps);
    // console.log('this.props', this.props);
    // console.log('this.state', this.state);
  }

  componentWillUnmount() {
    if (this.state.closeTimeout!==null) {
      clearTimeout(this.state.closeTimeout);
    }
  }

  render() {

    const { handleClearMessage, handleLogout, loggedIn } = this.props;
    const { messageInfo } = this.state;
    const { id, message, variant, logout } = messageInfo || {};
    // console.log(messageInfo, message, typeof message, typeof 'asdf')
    // return null;

    if (messageInfo===null) return null;

    let heading;
    let background;
    switch (variant) {
      case 'success':
        heading = 'Success';
        background = 'status-ok';
        break;
      case 'info':
        heading = 'Info';
        background = 'neutral-3';
        break;
      case 'warning':
        heading = 'Warning';
        background = 'status-warning';
        break;
      case 'error':
        heading = 'Error';
        background = 'status-error';
        break;
      default:
        heading = '';
        background = 'status-unknown';
        break;
    }

    return (
      <Layer
        className="StillHere"
        // full={true}
        margin="large"
        model={true}
        onClickOutside={() => handleClearMessage(id)}
        onEsc={() => handleClearMessage(id)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background={background}
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>{heading}</Heading>
            </Box>
          </Box>
          <Box fill style={{ overflowY: 'auto' }}>
            <Box pad="medium" margin="medium">{message}</Box>
          </Box>
          <div>
            <Box justify="center" direction="row" >
              <Box>
                {loggedIn && logout ?
                  <Button
                    // icon={<Clear />}
                    margin="small"
                    label="Logout"
                    type="submit"
                    onClick={() => {
                      handleClearMessage(id);
                      handleLogout();
                    }}
                  />
                : ''}
              </Box>
              <Box>
                <Button
                  // icon={<Close />}
                  margin="small"
                  label="OK"
                  type="submit"
                  primary={true}
                  onClick={() => handleClearMessage(id)}
                />
              </Box>
            </Box>
          </div>
        </Box>
      </Layer>
    );
  }
}

const mapStateToProps = (state) => {
  const { loggedIn } = state.auth;
  const messages = state.messages.items;
  return {
    loggedIn,
    messages,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    handleClearMessage: (id) => dispatch(clearMessages(id)),
    handleLogout: () => dispatch(logout()),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Messages);