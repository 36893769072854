import React from 'react';
import { Box, Heading } from 'grommet';

const LoadingOverlay = (props) => (
  <Box 
    className="LoadingOverlay"
    align='center'
    justify='center'
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(255,255,255,0.9)',
      zIndex: 10,
    }}
  >
    <Heading
      alignSelf="center"
    >
      Loading...
    </Heading>
  </Box>
);

export default LoadingOverlay;