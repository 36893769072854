// geocoded using http://www.mapdevelopers.com/geocode_tool.php

const locations = [
  {
    label: 'Trans Wellness Center',
    address: '3055 Wilshire Blvd., Suite 360, Los Angeles, CA 90010',
    // Latitude: 34.0617234 Longitude: -118.3030861
    position: { lat:  34.0617234, lng: -118.3030861 },
    selected: true,
    value: 'Trans Wellness Center',
  },
  {
    label: 'Other',
    address: null,
    // Latitude: NA
    position: null,
    selected: false,
    value: 'Other',
  },
];

export default locations;