import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Button, Heading, Layer } from 'grommet';
import { Close } from 'grommet-icons';
import Help from './Help';

class HelpDialog extends Component {

  constructor(props){
    super(props);
    this.state = {
      autoClose: null,
    };
  }

  componentDidMount() {
    const autoClose = setTimeout(() => this.props.toggle(false), 60000);
    this.setState({ autoClose });
  }

  componentWillUnmount() {
    if (this.state.autoClose!==null) {
      clearTimeout(this.state.autoClose);
    }
  }

  render() {

    const { open, toggle } = this.props;

    if (!open)
      return null;

    return (
      <Layer
        className="HelpDialog"
        full={true}
        margin="large"
        model={true}
        onClickOutside={() => toggle(false)}
        onEsc={() => toggle(false)}
        plain={false}
        responsive={true}
      >
        <Box fill>
          <Box
            direction='row'
            align='center'
            justify='between'
            background="light-2"
            pad={{ left: 'medium', right: 'small', vertical: 'small' }}
            elevation='medium'
            style={{ zIndex: '1' }}
          >
            <Box flex>
              <Heading level="2" style={{ margin: 0 }}>FAQ</Heading>
            </Box>
            <Box>
              <Button icon={<Close />} label="Close" onClick={() => toggle(false)} />
            </Box>
          </Box>
          <Box fill style={{ padding: '24px', overflowY: 'auto' }}>
            <Help />
          </Box>
        </Box>
      </Layer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HelpDialog);