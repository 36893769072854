const restrictionLengths = [
  {
    label: 'Permanently Restricted',
    value: '1',
  },
  {
    label: 'Temporary Suspension',
    value: '2',
  },
  {
    label: 'Eligible for Services',
    value: '3',
  },
  {
    label: 'Day by Day',
    value: '4',
  },
  {
    label: 'Appointment Only',
    value: '5',
  },
];

export default restrictionLengths;

// SELECT
// GROUP_CONCAT(
//     CONCAT(
//         "  {\n",
//             "    label: '", label, "',\n"
//             "    value: '", `value`, "',\n"
//         '  },'
//     )
//     SEPARATOR
//     "\n"
// )
// FROM `civicrm_option_value`
// WHERE option_group_id=110