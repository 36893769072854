import constants from '../constants';
import {default as uuid} from "uuid";

export const addMessage = (message, variant=null, logout=false) => ({
  type: constants.ADD_MESSAGE,
  value: {
    id: uuid.v4(),
    message: message,
    variant: variant,
    logout: logout,
  },
})

export const clearMessages = (uuid=null) => {
  if (uuid===null) {
    return {
      type: constants.CLEAR_ALL_MESSAGES,
    };
  }
  return {
    type: constants.CLEAR_SINGLE_MESSAGE,
    value: uuid,
  };
}