import initialState, { defaultState } from "../initialState";
import constants from "../constants";
import { cloneDeep } from 'lodash-es';

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const singleReducer = (state = initialState.single, action) => {
  // console.log("Running action:", action);
  const newState = {...state};
  
  switch(action.type)
  {
    case constants.SINGLE_LOADING_STATE:
      newState[action.value.single].isFetching = action.value.loadingState.length>0;
      newState[action.value.single].loadingState = action.value.loadingState;
      return newState;
    case constants.SINGLE_STATE:
      newState[action.value.single].state = action.value.state;
      return newState;
    case constants.SINGLE_DATA:
      newState[action.value.single].data = action.value.data===null ? null : cloneDeep(action.value.data);
      return newState;
    case constants.LOGOUT:
      return cloneDeep(defaultState.single);
    default:
      return newState;
  }
}

export default singleReducer;