import { connect } from 'react-redux';
import store from 'store';
import { barcodeAuthorize, checkin } from 'store/actions/auth';
import { addMessage } from 'store/actions/messages';
import BarcodeBase from '../../Auth/BarcodeBase';
import beep from 'assets/audio/beep';

const mapStateToProps = (state) => {
  const { isFetching } = state.auth;
  return {
    loading: isFetching, // dont scan when we are doing other stuff
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: code => {
      return barcodeAuthorize(code)
      .then(json => {
        
        beep.play();

        const { access_civicrm, contact_id } = json.values;

        if (!access_civicrm)
          throw new Error('User not authorized');

        const state = store.getState();
        
        const { 
          auth: { contactId },
        } = state;

        dispatch(checkin(contactId, contact_id));

        return json;
      })
      .catch(error => {
        dispatch(addMessage(error.message, 'error'));
        return false;
      });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeBase);