// view-source:https://cozmo.github.io/jsQR/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { barcodeLogin } from 'store/actions/auth';
import { Box, Heading } from 'grommet';

import Barcode from './ApprovedBy/Barcode';
import UsernamePassword from './ApprovedBy/UsernamePassword';

import './ApprovedBy/styles.css';

class ApprovedBy extends Component {

  render() {

    return (
      <div className="ApprovedBy">
        <p>Staff must scan their card to authorize entry.</p>
        <Box className="auth-options" flex direction='row-responsive' justify="center">
          <Box pad={{ vertical: "medium" }} flex="grow" basis="2">
            <Box className="auth-option" pad="small" round="small">
              <Heading margin={{ top: "small", bottom: "small", left: "none", right: "none" }}>
                Login
              </Heading>
              <Heading level="4" margin={{ top: "small", bottom: "medium", left: "none", right: "none" }}>
                Use your username and password.
              </Heading>
              <UsernamePassword />
            </Box>
          </Box>
          <Box pad="medium" basis="1">
            <Box className="or-box" direction="column" justify="center" align="center" pad={{ vertical: "small" }}>
              <Box className="or-line" border={{ size: "small", color: "dark-1" }} flex="grow"></Box>
              <Box pad={{ vertical: "medium" }} >OR</Box>
              <Box className="or-line" border={{ size: "small", color: "dark-1" }} flex="grow"></Box>
            </Box>
          </Box>
          <Box pad={{ vertical: "medium" }} flex="grow" basis="2">
            <Box className="auth-option" pad="small" round="small">
              <Heading margin={{ top: "small", left: "none", right: "none", bottom: "medium" }}>
                Scan ID Card
              </Heading>
              <Barcode />
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isFetching } = state.auth;
  return {
    loading: isFetching, // dont scan when we are doing other stuff
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: code => dispatch(barcodeLogin(code)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ApprovedBy);