import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Box, Image } from 'grommet';
import logo from 'assets/images/logohoz-white.png';

class AppBar extends Component {

  render() {

    return (
      <div className="AppBar">
        <Box
          tag='header'
          direction='row'
          align='center'
          justify='between'
          background="brand"
          pad={{ left: 'medium', right: 'small', vertical: 'small' }}
          elevation='medium'
          {...this.props}
        >
          <Image
            className="logo"
            alignSelf="start"
            margin="none"
            src={logo}
          />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { testMode } = state.auth;
  return {
    testMode,
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AppBar);