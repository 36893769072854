import initialState/*, { defaultState } */ from "../initialState";
import constants from "../constants";
// import { cloneDeep } from 'lodash-es';

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const messagesReducer = (state = initialState.messages, action) => {
    // console.log("Running action:", action);
    const newState = {...state};
    
    switch(action.type)
    {
        case constants.ADD_MESSAGE:
            const items = [...newState.items];
            items.push(action.value);
            newState.items = items; // need to change this so that react knows to rerender
            return newState;
        case constants.CLEAR_SINGLE_MESSAGE:
            newState.items = newState.items.filter(msg => msg.id!==action.value);
            return newState;
        case constants.TEST_MODE:
        case constants.CLEAR_ALL_MESSAGES:
            newState.items = [];
            return newState;
        // case constants.LOGOUT:
        //     return cloneDeep(defaultState.messages);
        default:
            return newState;
    }
}

export default messagesReducer;