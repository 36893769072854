import React, { Component } from 'react';
import { Box, /*CheckBox,*/ RadioButton } from 'grommet';

class LocationSelect extends Component {

  render() {

    const { handleChange, locations, selected } = this.props;

    return (
      <div className="LocationSelection">
        {locations
        .filter(location => location.position!==null)
        .map((location,i) => (
          <Box
            key={i}
            pad="small"
            direction="row"
            onClick={e => handleChange(i)}
            style={{ cursor: 'pointer', minHeight: 'auto' }}
            flex
          >
            <Box justify="center" style={{ minWidth: 84 }}>
              {/* <CheckBox 
                name={"location_"+i}
                label={location.label}
                checked={selected.includes(i)}
                onChange={handleChange}
              /> */}
              <RadioButton 
                name={"locations"}
                // label={location.label}
                checked={selected.includes(i)}
                onChange={e => null/* handleChange(i) */}
              />
            </Box>
            <Box>
              {location.label}
              <br />
              <small>{location.address}</small>
            </Box>
          </Box>
        ))}
      </div>
    );
  }
}

export default LocationSelect;