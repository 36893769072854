// Calling it index allows the import to be just import Store from 'store'.
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducers';
// import constants from './constants';


const store = createStore(
  reducer,
  // preloadedState,
  composeWithDevTools(
    applyMiddleware(thunk),
  )
);
// // Called when the state has changed.
// store.subscribe(() => {
  
//   const state = store.getState();
//   // console.log('currenstate', state);
//   const storeState = Object.assign({}, state, {
//     // don't save some things
//     messages: {
//       items: [],
//     },
//   });

//   localStorage.setItem(constants.LOCAL_STORAGE_KEY, JSON.stringify(storeState));
// }) 

export default store;