import initialState from "../initialState";
import constants from "../constants";
import * as moment from 'moment-timezone';

const barcodeReducer = (state = initialState.barcode, action) => {

  /**
   * keep the last minute of scan
   * purge histoy every time we
   * get a new scan
   */
  // const newState = [...state];
  let newState = state.filter(scan => {
    const { time } = scan;
    const scanTime = moment(time);
    const oneMinuteAgo = moment().subtract(1, 'minute');
    // console.log({ afterAMinute: scanTime.isAfter(oneMinuteAgo), scanTime: scanTime.toISOString(), oneMinuteAgo: oneMinuteAgo.toISOString() })
    if (scanTime.isAfter(oneMinuteAgo))
      return true;
    return false;
  });

  switch(action.type)
  {
    case constants.ADD_SCAN:
      newState.push(action.value);
      return newState;
    // case constants.LOGOUT:
    //   newState = [];
    //   return newState;
    default:
      return newState;
  }
}

export default barcodeReducer;