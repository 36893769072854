import constants from '../constants';

export const windowResize = (width, height)=> {
  const mobileMode = width<1025;
  return {
    type: constants.WINDOW_RESIZE,
    value: {
      width: width,
      height: height,
      mobileMode: mobileMode,
    },
  };
}
