import initialState from "../initialState";
import constants from "../constants";

// Reducers: basically translates the 'actions' objects.
// Naming them reducers is not recommended with the user of combineReducers.
// It's better to name them based on the state slice they are at to represent their data.
const displayReducer = (state = initialState.display, action) => {
  // console.log("Running action:", action);
  const newState = {...state};
  
  switch(action.type)
  {
    case constants.WINDOW_RESIZE:
      const { width, height, mobileMode } = action.value;
      newState.width = width;
      newState.height = height;
      newState.mobileMode = mobileMode;
      return newState;
    default:
      return newState;
  }
}

export default displayReducer;