import { connect } from 'react-redux';
import { login } from 'store/actions/auth';
import { initialize, reduxForm } from 'redux-form';
import UsernamePasswordBase from './UsernamePasswordBase';

const formName = 'login';

const validate = vals => {
  const errors = {};

  ['username', 'password'].forEach(fieldName => {
    if (!(fieldName in vals) || fieldName.length<1) {
      errors[fieldName] = 'Required';
    }
  });
 
  return errors;
}

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: async data => {
      const { username, password } = data;
      const loggedIn =  await dispatch(login(username, password));
      let newData = {};
      if (loggedIn===false)
        newData.username = username;
      dispatch(initialize([formName], newData));
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: formName,
    initialValues: {},
    validate,
  })(UsernamePasswordBase)
);