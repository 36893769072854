import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkin, logout } from 'store/actions/auth';
import {
  updateCurrentState,
  updateLoadingState,
  setSingleData,
} from 'store/actions/single';
import { addMessage } from 'store/actions/messages';
import { Form, reduxForm } from 'redux-form';
import { Box, Button, Heading } from 'grommet';
import { Checkmark, Close, Validate } from 'grommet-icons';
import './Checkin/styles.css';
import { URLBuild as handleCiviURLBuild } from 'helpers/CiviCRM';
import Map from './Checkin/Map';
import StillHere from './Checkin/StillHere';
import WelcomeHeading from './Checkin/WelcomeHeading';
import restrictionLengthList from 'helpers/restrictionLengths';
import restrictionReasonsList from 'helpers/restrictionReasons';
import * as moment from 'moment-timezone';
import ApprovedByDialog from './Checkin/ApprovedByDialog';

const handlers = {
  'click': null,
  'scroll': null,
  'touchstart': null,
  'touchend': null,
  'touchmove': null,
  'touchcancel': null,
  'keydown': null,
  'keyup': null,
};

class Checkin extends Component {

  constructor(props){
    super(props);
    this.state = {
      stillHereTimeout: null,
      stillHereOpen: false,
      approvedByOpen: false,
    };
  }

  componentDidMount() {
    
    this.loadData();

    // setup events to trigger still here
    this.setupStillHereTimeout();
    const handler = e => this.setupStillHereTimeout();
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] = handler;
      window.addEventListener(eventName, handler, true);
    });
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentWillUnmount() {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    // remove events that trigger still here
    Object.keys(handlers).forEach(eventName => {
      handlers[eventName] && window.removeEventListener(eventName, handlers[eventName], true);
    });
  }

  calculateCurrentState = () => {
    const tableState = [
      this.props.contactId,
    ].join('|');
    return tableState;
  }

  loadData = () => {
    
    const currentState = this.calculateCurrentState();
    const { state, loadingState } = this.props;
    // console.log({ currentState, state, loadingState})
    if (
      currentState === state ||
      currentState === loadingState
    ) return;

    this.props.handleUpdateLoadingState(currentState);

    const returnFields = [
      // intake
      'custom_180', // enrollment date/time
      // custom twc data
      'custom_142', // Date of Restriction
      'custom_143', // Reason for Restriction
      'custom_144', // Length of Restriction
      'custom_145', // Description of Restriction
    ];

    const url = handleCiviURLBuild('Contact','get',{
      sequential:1,
      id: this.props.contactId,
      return: returnFields,
    });
    // console.log({ url })
    
    return fetch(url, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
    .then(response => response.json())
    .then(json => {
      if (json.is_error)
        throw new Error(json.error_message);
      return json;
    })
    .then(data => data.values[0])
    .then(data => {
      // civicrm is inconsistant in returning variables
      returnFields.forEach(field => {
        if (!(field in data))
          data[field] = '';
      });

      const { custom_180, custom_142, custom_143, custom_144 } = data;

      data.intakeDate = custom_180 ? moment.tz(custom_180, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;

      data.restrictionDate = custom_142 ? moment.tz(custom_142, 'YYYY-MM-DD HH:mm:ss', "America/Los_Angeles").format('MMMM Do, YYYY') : null;

      const restrictionLength = restrictionLengthList.find(length => length.value===custom_144);
      data.restrictionLength = restrictionLength ? restrictionLength.label : null;

      const restrictionReasons = restrictionReasonsList.filter(reason => custom_143 && custom_143.includes(reason.value));
      data.restrictionReasons = restrictionReasons ? restrictionReasons.map(reason => reason.label).join(', ') : null;

      // console.log(data);
      return data;
    })
    .then(data => {
      // update list data
      this.props.handleUpdateCurrentState(currentState);
      this.props.handleSetSingleData(data);
      this.props.handleUpdateLoadingState('');
    })
    .catch(e => {
      this.props.handleMessage(e.message, 'error');
    });
    
  }

  submitForm = (values) => {
    const { contactId, handleCheckin } = this.props;
    handleCheckin(contactId);
  }

  setupStillHereTimeout = () => {

    if (this.state.stillHereTimeout!==null) {
      clearTimeout(this.state.stillHereTimeout);
    }

    const stillHereTimeout = setTimeout(() => this.toggleStillHere(), 60000); // 60 seconds
    this.setState({ stillHereTimeout });
  }

  toggleStillHere = (state=null) => {

    const stillHereOpen = state===null ? !this.state.stillHereOpen : state;
    if (!stillHereOpen) {
      this.setupStillHereTimeout();
    }
    this.setState({ stillHereOpen });
  }

  toggleApprovedByOpen = (state=null) => this.setState({approvedByOpen: state===null ? !this.state.approvedByOpen : state})

  render() {

    const { contact, extraContactData, handleLogout, handleSubmit, isFetching } = this.props;
    const { stillHereOpen, approvedByOpen } = this.state;
    // console.log({ contact, extraContactData })    

    const { image_URL } = contact;
    let contactImage = null;
    const imageContainerStyles = {};
    if (image_URL) {
      const elem = document.createElement('textarea');
      elem.innerHTML = image_URL;
      contactImage = elem.value;
      imageContainerStyles.backgroundImage = `url('${contactImage}')`;
    }

    return (
      <Box className="Checkin" direction='column' flex align="center" justify="center">
        <Box className="headingbox" basis="15%">
          <WelcomeHeading />
        </Box>
        <Box className="clientbox" basis="80%" border={{ size: 'medium' }} pad="medium">
          {extraContactData==null ? 
            <Box>Loading...</Box>
          : (
            <Box direction="row" fill>
              <Box basis="40%" direction='column' fill margin={{ right: 'small' }} justify="center" align="center">
                {contactImage ? <Box className="clientimagebox" style={imageContainerStyles} fill></Box> : <Box>-- No Image --</Box> }
              </Box>
              <Box basis="60%" margin={{ left: 'small' }} pad={{ left: 'medium', top: 'medium' }}>
                <Form onSubmit={handleSubmit(this.submitForm)}>
                  {!isFetching && extraContactData.restrictionDate &&
                    <Box border="bottom" background="status-critical" style={{ textAlign: 'center' }}>
                      <Heading level="3" margin="small">RESTRICTED</Heading>
                    </Box>
                  }
                  <Box direction="row" border="bottom">
                    <Box basis="1/2" pad="xsmall"><strong>Client ID #</strong></Box>
                    <Box basis="1/2" pad="xsmall">{contact.id}</Box>
                  </Box>
                  <Box direction="row" border="bottom">
                    <Box basis="1/2" pad="xsmall"><strong>Client Name</strong></Box>
                    <Box basis="1/2" pad="xsmall">{contact.display_name}</Box>
                  </Box>
                  <Box direction="row" border="bottom">
                    <Box basis="1/2" pad="xsmall"><strong>Birth Date</strong></Box>
                    <Box basis="1/2" pad="xsmall">{contact.birth_date}</Box>
                  </Box>
                  {/*
                    // intake
                    'custom_180', // enrollment date/time
                    // restricted
                    'custom_142', // Date of Restriction
                    'custom_143', // Reason for Restriction
                    'custom_144', // Length of Restriction
                    'custom_145', // Description
                  */}
                  {!isFetching &&
                    <Box direction="row" border="bottom" background={extraContactData.intakeDate ? "transparent" : "status-critical"}>
                      <Box basis="1/2" pad="xsmall"><strong>Enrollment Date</strong></Box>
                      <Box basis="1/2" pad="xsmall">{extraContactData.intakeDate}</Box>
                    </Box>
                  }
                  {!isFetching && extraContactData.restrictionDate ?
                    <>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Date of Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.restrictionDate}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Length of Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.restrictionLength}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Reason for Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.restrictionReasons}</Box>
                      </Box>
                      <Box direction="row" border="bottom">
                        <Box basis="1/2" pad="xsmall"><strong>Description of Restriction</strong></Box>
                        <Box basis="1/2" pad="xsmall">{extraContactData.custom_145}</Box>
                      </Box>
                      <Box direction="row" justify="center" align="center">
                        <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                          <Button margin="none" label="Cancel" icon={<Close />} onClick={() => handleLogout()} />
                        </Box>
                        <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                          <Button margin="none" label="Approved By" color="neutral-1" icon={<Validate />} onClick={() => this.toggleApprovedByOpen(true)} primary={true} />
                        </Box>
                      </Box>
                    </>
                  :
                    <Box direction="row" justify="center" align="center">
                      <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                        <Button margin="none" label="Cancel" icon={<Close />} onClick={() => handleLogout()} />
                      </Box>
                      <Box pad={{ top: "small", bottom: "xsmall", left: "xsmall", right: "xsmall" }}>
                        {extraContactData.intakeDate ?
                          <Button margin="none" label="Confirm" color="neutral-1" icon={<Checkmark />} type="submit" primary={true} />
                        :
                          "Please see desk to complete an intake"
                        }
                      </Box>
                    </Box>
                  }
                </Form>
                <ApprovedByDialog open={approvedByOpen} toggle={this.toggleApprovedByOpen} />
              </Box>
            </Box>
          )}
        </Box>
        <Map />
        <StillHere open={stillHereOpen} toggle={this.toggleStillHere} />
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { 
    auth: { contact, contactId },
    display: { mobileMode },
    single: { extraContact },
  } = state;

  const {
    isFetching,
    data,
    loadingState,
  } = extraContact;
  
  return {
    contact,
    contactId,
    mobileMode,

    isFetching,
    extraContactData: data,
    state: extraContact.state,
    loadingState,
  };
}

const mapDispatchToProps = dispatch => {

  return {
    handleLogout: () => dispatch(logout()),
    handleMessage: (message, variant=null, logout=false) => dispatch(addMessage(message, variant, logout)),
    handleUpdateCurrentState: state => dispatch(updateCurrentState('extraContact', state)),
    handleUpdateLoadingState: loadingState => dispatch(updateLoadingState('extraContact', loadingState)),
    handleSetSingleData: data => dispatch(setSingleData('extraContact', data)),
    handleCheckin: contact_id => dispatch(checkin(contact_id)),
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'checkin',
  })(Checkin)
);
